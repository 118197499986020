import { useState } from "react"
import { Link } from "react-router-dom"
import ListGroup from 'react-bootstrap/ListGroup';
import '../css/home.css'
import Process from "./process";
import { Button, Card, Col, Row } from "react-bootstrap";


const Home = () => {

    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-0 psyd-font-accent rounded-top text-center">
                        <h1>AI Therapy, Backed by Clinical Expertise</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
                    <div className="col-md-6 p-0 d-flex justify-content-center">
                        <div className="card">
                            <img className="card-img-top img-fluid" src="../img/team.png" alt="Online Therapy" />
                        </div>
                    </div>
                    <div className="col-md-6 p-3">
                        <p> Feeling overwhelmed or just need someone to talk to? It can be tough to find the right mental health support when you need it. That's where we come in.<br></br>
                        </p>
                        <p> <b>Psyd</b> is an advanced AI-powered therapy platform, designed to understand your unique concerns and provide tailored, meaningful support. Our platform is backed by: <br></br>
                        </p>
                        <div className="card w-100 psyd-secondary-bg-color border-0 px-0">
                            <div className="card-body">
                                <ListGroup className="p-0">
                                    <ListGroup.Item className="psyd-primary-bg-color psyd-font-light shadow-lg rounded p-2">
                                        <i className="bi bi-book me-2 fs-4"></i>
                                        <b>Decades of Expertise</b> in mental health, neuroscience, and technology, ensuring a deep understanding of your needs.
                                    </ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-2">
                                        <i className="bi bi-person-lines-fill me-2 fs-4"></i>
                                        <b>Licensed Mental Health Professionals</b> who guarantee trusted, ethical, and compassionate care.</ListGroup.Item>
                                    <ListGroup.Item className="psyd-primary-bg-color psyd-font-light shadow-lg rounded p-2">
                                        <i className="bi bi-gear-fill me-2 fs-4"></i>
                                        <b>Leading Neuroscientists</b> at the forefront of innovation in AI-driven therapy.</ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-2">
                                        <i className="bi bi-shield-check me-2 fs-4"></i>
                                        <b>Board-Certified Physicians</b> offering medical insight and oversight to ensure comprehensive support</ListGroup.Item>
                                </ListGroup>
                            </div>
                        </div>
                        At Psyd, our mission is simple: to make mental health support accessible to anyone, anywhere, anytime.<br></br>
                        <div className="d-flex justify-content-center">
                            <Link to="/sessions">
                                <Button>Start a Session</Button>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container-fluid mt-5 px-4 rounded">
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font align-items-center rounded">
                    <div className="col-md-6 p-0 d-flex justify-content-center">
                        <div className="card w-100 psyd-secondary-bg-color border-0 px-0">
                            <div className="card-body">
                                <ListGroup className="p-0">
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-2 bi bi-clock">&nbsp;<b>24/7 Availability</b></ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-2 bi bi-heart">&nbsp;<b>Personalized Therapy</b></ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-2 bi bi-lock">&nbsp;<b>Private and Unbiased</b></ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-2 bi bi-calendar">&nbsp;<b>Flexible Session Options</b></ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-2 bi bi-house-door">&nbsp;<b>Open to All, No Insurance Needed</b></ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-2 bi bi-lightning">&nbsp;<b>Instant Access, No Downloads</b></ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-2 bi bi-file-earmark-text">&nbsp;<b>Therapy Session Summaries</b></ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-2 bi bi-people">&nbsp;<b>Human Integration</b></ListGroup.Item>
                                </ListGroup>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 p-0 d-flex justify-content-center align-items-start">
                        <div className="card w-100">
                            <img className="card-img-top img-fluid" src="../img/Chat.jpg" alt="Online Therapy" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home


