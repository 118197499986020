import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Cookies from 'js-cookie';

const Profile = ({ changeMenuOption, guserSubscriptionPlan, setSubscriptionPlan }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isHIVisible, setIsHIVisible] = useState(false);

  useEffect(() => {
    setSubscriptionPlan();
    if (guserSubscriptionPlan == 'Guest' || guserSubscriptionPlan == 'Free') {
      setIsVisible(false);
    } else { setIsVisible(true) }
    if (guserSubscriptionPlan == 'Premium') {
      setIsHIVisible(true);
    } else { setIsHIVisible(false) }

  }, [setSubscriptionPlan]);

  const handleLogout = () => {
    const useridcookieExists = Cookies.get('userid') !== undefined;
    if (useridcookieExists) {
      Cookies.remove('userid'); // Use string literal for cookie name
    }

    const userinfocookieExists = Cookies.get('userinfo') !== undefined;
    if (userinfocookieExists) {
      Cookies.remove('userinfo'); // Use string literal for cookie name
    }
    const usersubscriptioninfoExists = Cookies.get('usersubscriptioninfo') !== undefined;
    if (usersubscriptioninfoExists) {
      Cookies.remove('usersubscriptioninfo'); // Use string literal for cookie name
    }
    Cookies.remove("personaname");
    Cookies.remove("photourl");
    Cookies.remove("sessionid");
    Cookies.remove("sessioninfo");
    Cookies.remove("sessionname");
    Cookies.remove('therapistpersonaid');
    Cookies.remove('personaname');
    Cookies.remove('photourl');
    Cookies.remove('jobcategoryid');
    Cookies.remove('professionalstatusid');
    Cookies.remove('agegroupid');
    Cookies.remove('relationshipstatusid');

    changeMenuOption('login', 'Login', '/login');
    changeMenuOption('process', 'How it works', '/process');
    setSubscriptionPlan();

  };

  return (

    <div id='homediv' className="container-fluid px-0">
      <div className="container-fluid px-4 rounded">
        <div className="row mt-2 mb-0 rounded">
          <div className="col-md-12 psyd-primary-bg-color p-0 psyd-font-accent rounded-top text-center">
            <h1>Settings</h1>
          </div>
        </div>
        <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
          <div className="col-md-12 p-3 d-flex justify-content-center">
            Keeping your profile information, including details such as your preferred name, pronouns, and user preferences, up to date helps us provide a more personalized and supportive therapy experience.
          </div>
        </div>
        <div className="row p-3 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg rounded justify-content-center">
          <div className="col-md-12 text-left">
            <Link to='/user' className='custom-link'>
              Update Profile Information
            </Link>
          </div>
        </div>
        {isVisible && (
          <div className="row p-3 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg rounded justify-content-center">
            <div className="col-md-12 text-start">
              <Link to='/theme-select' className='custom-link'>
                Customize User Interface
              </Link>
            </div>
          </div>
        )}
        {isHIVisible && (
          <div className="row p-3 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg rounded justify-content-center">
            <div className="col-md-12 text-start">
              <Link to='/share' className='custom-link'>
                Human Integration
              </Link>
            </div>
          </div>
        )}

        < div className="row p-3 mt-0 mb-1 psyd-font rounded justify-content-center">
          <div className="col-md-12 text-center">
            <Link to='/'>
              <Button onClick={handleLogout}>Logout</Button>
            </Link>
          </div>
        </div>
      </div>
    </div >
  );
};
export default Profile;
