import React, { useState, useEffect } from 'react';
import { Button, Form } from "react-bootstrap";
import axios from 'axios';
import '../css/home.css';
import { Link } from "react-router-dom";
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';




const RelationshipStatus = ({ guserSubscriptionPlan }) => {
    const [refvalues, setrefvalues] = useState([]);
    const [relationshipstatusID, setrelationshipstatusID] = useState(1);
    const navigate = useNavigate();


    const fetchRelationshipStatus = async () => {
        try {
            const response = await axios.get('/api/allrelationshipstatus');
            const temprefvalues = response.data.refvalues;
            const parserefvalues = temprefvalues?.map(temprefvalue => JSON.parse(temprefvalue)) || [];
            setrefvalues(parserefvalues);
        } catch (error) {
            console.error('Error fetching realationship status', error);
        }
    };
    // Function to handle radio button selection
    const handleRadioChange = (event) => {
        setrelationshipstatusID(event.target.value);
        console.log("Selected relationship status:", event.target.value);
        Cookie.set('relationshipstatusid', event.target.value);
    };
    useEffect(() => {
        if (guserSubscriptionPlan == 'Guest' || guserSubscriptionPlan == 'Free') {
            navigate('/messages');
        }
        const sessioninfoCookie = Cookies.get('sessioninfo');
        let sessioninfo = {};
        if (sessioninfoCookie) {
            sessioninfo = JSON.parse(sessioninfoCookie);
            setrelationshipstatusID(sessioninfo.relationship_status_id);
            Cookie.set('relationshipstatusid', sessioninfo.relationship_status_id);
        } else{Cookie.set('relationshipstatusid', relationshipstatusID)}
        fetchRelationshipStatus();

    }, []);

    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-0 psyd-font-accent rounded-top text-center">
                        <h1>Relationship Status</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
                    <div className="col-md-12 p-1 d-flex justify-content-center">
                        Relationship status significantly influences both physical and mental health. Our AI-powered therapy tailors strategies and techniques to address the specific needs and concerns of individuals in various stages of life.             
                    </div>
                </div>
            </div>
            <div className="container mt-3 d-flex justify-content-center align-items-top">
                <div className="col">
                    <div className="list-group p-0 d-flex justify-content-center align-items-top">
                        {refvalues?.map((refvalue, index) => (
                            <Form.Check
                                key={index}
                                type="radio"
                                id={`refvalue-${index}`}
                                label={
                                    <span style={{ marginLeft: '20px' }}>{refvalue.relationship_status}</span> // Add spacing here
                                }
                                value={refvalue.relationship_status_id}
                                name="refvalue" // Grouping the radio buttons
                                className="psyd-secondary-bg-color rounded mb-2"
                                onChange={handleRadioChange}
                                checked={relationshipstatusID == refvalue.relationship_status_id}
                                style={{ height: '30px', display: 'flex', alignItems: 'center' }} // Adjust height and center alignment
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                <Link to={'/agegroup'} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button>&lt; Age Group</Button>
                </Link>
                <Link to={'/professionalstatus'} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button>Professional Status &gt;</Button>
                </Link>
            </div>
        </div>
    );
}

export default RelationshipStatus;
