import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom"
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

// Set axios default settings to include cookies
axios.defaults.withCredentials = true;


function Plans2() {
    const [userId, setUserID] = useState('');
    const navigate = useNavigate();

    const handlePurchase = (plan) => {
        Cookies.set("purchaseplan", plan);
        navigate('/purchase');
    }

    const fetchPlans = async () => {
        try {

            let userid_cookie = Cookies.get('userid')
            if (userid_cookie) { setUserID(userid_cookie) }
        } catch (error) {
            console.error('Error fetching plans', error);
        }
    }


    useEffect(() => {
        fetchPlans();
    }, []);
    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-0 psyd-font-accent rounded-top text-center">
                        <h1>Subscription Plans</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font align-items-center rounded">
                    <div className="col-md-6 p-3">
                        Our therapy platform offers a range of flexible plans to accommodate different needs: <br />
                        <b>Anonymous Guest Plan</b>, which allows 30 minutes of therapy time per month without session history retention. <br />
                        <b>Free Plan</b> increases access to 60 minutes of therapy time per month and retains history for three months, also at no cost. <br />
                        <b>Basic Plan</b> ($9.99/month) provides 120 minutes of therapy time per month and 12 months of history, while <br />
                        <b>Premium Plan</b> ($19.99/month) offers 240 minutes of therapy time per month with 24 months of history retention.<br />
                        We’re excited to let you know that both the Basic and Premium plans of our online therapy platform come with a <b>one-month free trial</b>. During this trial period, you can explore all the features and get a feel for the service.<br></br>
                        Please be aware that at the end of the trial, you will be charged for the plan unless you cancel before the trial period ends. If you choose not to continue, simply cancel before the trial is up to avoid any charges.<br></br>
                        Feel free to reach out if you have any questions or need assistance!
                    </div>
                    <div className="col-md-6 p-3">
                        <div className="table-responsive">
                            <table className="table-bordered psyd-secondary-bg-color align-items-center psyd-font" style={{ borderSpacing: '10px 10px' }}>
                                <thead className="table-header psyd-primary-bg-color psyd-font-light">
                                    <tr className='table-header'>
                                        <th className='text-left'>Feature Name</th>
                                        <th className='text-center'>Basic Plan</th>
                                        <th className='text-center'>Premium Plan</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="table-row">
                                        <td className='text-left'>Sessions Mins</td>
                                        <td className='text-center'>120</td>
                                        <td className='text-center'>240</td>
                                    </tr>
                                    <tr className="table-row  psyd-primary-bg-color psyd-font-light">
                                        <td className='text-left'>Price $/month</td>
                                        <td className='text-center'>9.99</td>
                                        <td className='text-center'>19.99</td>
                                    </tr>
                                    <tr className="table-row">
                                        <td className='text-left'>Customizable User Interface</td>
                                        <td className='text-center'>✅</td>
                                        <td className='text-center'>✅</td>
                                    </tr>
                                    <tr className="table-row  psyd-primary-bg-color psyd-font-light">
                                        <td className='text-left'>Personalized Therapy</td>
                                        <td className='text-center'>✅</td>
                                        <td className='text-center'>✅</td>
                                    </tr>
                                    <tr className="table-row">
                                        <td className='text-left'>Tailored Therapist Profile</td>
                                        <td className='text-center'>✅</td>
                                        <td className='text-center'>✅</td>
                                    </tr>
                                    <tr className="table-row  psyd-primary-bg-color psyd-font-light">
                                        <td className='text-left'>Download Past Sessions as PDF</td>
                                        <td className='text-center'>❌</td>
                                        <td className='text-center'>✅</td>
                                    </tr>
                                    <tr className="table-row">
                                        <td className='text-left'>Session Summary</td>
                                        <td className='text-center'>❌</td>
                                        <td className='text-center'>✅</td>
                                    </tr>
                                    <tr className="table-row  psyd-primary-bg-color psyd-font-light">
                                        <td className='text-left'>Human Integration</td>
                                        <td className='text-center'>❌</td>
                                        <td className='text-center'>✅</td>
                                    </tr>
                                    <tr className="table-row">
                                        <td className='text-left'>Tailored Therapist Personas</td>
                                        <td className='text-center'>❌</td>
                                        <td className='text-center'>✅</td>
                                    </tr>
                                    <tr className="table-row  psyd-primary-bg-color psyd-font-light">
                                        <td className='text-left'>30 days Trial</td>
                                        <td className='text-center'>✅</td>
                                        <td className='text-center'>✅</td>
                                    </tr>
                                    <tr className="table-row">
                                        <td className='text-left'>&nbsp;</td>
                                        <td className='text-center'>
                                            {userId ? (
                                                <Button onClick={() => handlePurchase('BASIC')}>Purchase</Button>
                                            ) : (
                                                <Link to='/login'>
                                                    <Button>Please login to purchase</Button>
                                                </Link>
                                            )}

                                        </td>
                                        <td className='text-center'>
                                            {userId ? (
                                                <Button onClick={() => handlePurchase('PREMIUM')}>Purchase</Button>
                                            ) : (
                                                <Link to='/login'>
                                                    <Button>Please login to purchase</Button>
                                                </Link>
                                            )}

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid px-4 rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-0 psyd-font-accent rounded-top text-center">
                        <h1>Therapy Time</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font align-items-center rounded">
                    <div className="col-md-6 p-0 d-flex justify-content-center">
                        <div className="card">
                            <img className="card-img-top img-fluid" src="../img/clock.jpg" alt="Online Therapy" />
                        </div>
                    </div>
                    <div className="col-md-6 p-3">
                        The total therapy duration is calculated from the time between the first and last message timestamps, excluding any breaks longer than 5 minutes. This allows you to take breaks without impacting the total therapy time. You can divide your total interaction time across multiple sessions, giving you the flexibility to start as many sessions as you need without being limited by the number specified in your plan. This approach helps you manage your therapy in a way that fits your schedules while still fulfilling the total minutes in your plan.
                    </div>

                </div>
            </div>

            <div>

            </div>
        </div>
    );
}

export default Plans2;
