import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Container } from 'react-bootstrap';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';

const User = ({guserSubscriptionPlan}) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [sharingCode, setSharingCode] = useState('');
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        preferred_name: '',
        date_of_birth: '',
        gender: '',
        pronouns: '',
    });

    // Handle form field change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    useEffect(() => {
        const userCookie = Cookies.get('userinfo');
        let userinfo = {};
        if (userCookie) {
            userinfo = JSON.parse(userCookie);
            setFormData(userinfo);  // Set form data from the fetched user
            setSharingCode(userinfo.sharing_code)
        }
        setErrorMessage('');
    }, []);

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        console.error(formData);
        const userCookie = Cookies.get('userinfo');
        let userinfo = {};
        let userId = ''
        if (userCookie) {
            userinfo = JSON.parse(userCookie);
            userId = userinfo.user_id;
        }
        axios.put('/api/user?userId=${userId}', formData) // PUT request to update the user
            .then((response) => {
                const userinfo = JSON.parse(response.data.user);
                console.log('userinfo:', userinfo);
                Cookie.set('userinfo', JSON.stringify(userinfo));
                setErrorMessage('Profile successfully updated.');

            })
            .catch((error) => {
                setErrorMessage('There was an error in updating the Profile information.');
                console.error('Error updating user:', error);
            });
    };

    return (
        <div id='homediv' className="container-fluid px-0">
             <div className="container-fluid px-4 rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-0 psyd-font-accent rounded-top text-center">
                        <h1>Profile Information</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
                    <div className="col-md-12 p-3 d-flex justify-content-center">
                    We encourage you to update your profile information, including your preferred name, pronouns etc. on our online therapy platform. This information will help us personalize your therapy sessions, ensuring a more comfortable and tailored experience. By knowing more about your preferences and how you identify, our AI-powered system can better adapt to your needs, creating a supportive and respectful environment for your mental health journey. Your privacy and comfort are our top priority, and this step will enhance the quality and relevance of your sessions.                    </div>
                </div>
            </div>            
            <div className="container mt-3" style={{ minHeight: '50vh' }}>

                <div className="row mb-3 g-2">
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>First Name</label>
                    </div>
                    <div className="col-md-3">
                        <input className="form-control" type="text" name="first_name" value={formData.first_name} onChange={handleChange} required style={{ height: '55px' }} />
                    </div>
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>Last Name</label>
                    </div>
                    <div className="col-md-3">
                        <input className="form-control" type="text" name="last_name" value={formData.last_name} onChange={handleChange} required style={{ height: '55px' }} />
                    </div>
                </div>
                <div className="row mb-3 g-2">
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>Preferred Name</label>
                    </div>
                    <div className="col-md-3">
                        <input className="form-control" type="text" name="preferred_name" value={formData.preferred_name} onChange={handleChange} style={{ height: '55px' }} />
                    </div>
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>Date of Birth</label>
                    </div>
                    <div className="col-md-3">
                        <input className="form-control" type="date" name="date_of_birth" value={formData.date_of_birth} onChange={handleChange} style={{ height: '55px' }} />
                    </div>
                </div>
                <div className="row mb-3 g-2">
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>Pronouns</label>
                    </div>
                    <div className="col-md-3">
                        <select className="form-select" name="pronouns" value={formData.pronouns} onChange={handleChange} style={{ height: '55px' }} >
                            <option value="">Select Pronouns</option>
                            <option value="he/him/his">He/Him/His</option>
                            <option value="she/her/hers">She/Her/Hers</option>
                            <option value="they/them/theirs">They/Them/Theirs</option>
                            {/* Add more options as needed */}
                        </select>                    </div>
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>Gender</label>
                    </div>
                    <div className="col-md-3">
                        <select className="form-select" name="gender" value={formData.gender} onChange={handleChange} style={{ height: '55px' }} >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Non-Binary">Non-Binary</option>
                            <option value="Prefer Not to Say">Prefer Not to Say</option>
                            {/* Add more options as needed */}
                        </select>                    </div>
                </div>
                <div className="row mb-3 g-2">
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>Integration Authorization Code</label>
                    </div>
                    <div className="col-md-3 p-3 psyd-font-light">
                    <label>{sharingCode}</label>
                    </div>
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>Subscription Plan</label>
                    </div>
                    <div className="col-md-3 p-3 psyd-font-light">
                    <label>{guserSubscriptionPlan}</label>
                    </div>

                </div>
                <div className="row mb-3 g-2">
                    <div className="col-md-12 rounded psyd-primary-bg-color">
                        <div className='psyd-font-light'>{errorMessage}</div>
                    </div>
                </div>
                <div className="row mb-3 g-2">
                    <div className="col-md-12 rounded psyd-primary-bg-color d-flex justify-content-center align-items-center">
                        <Button variant="primary" onClick={handleSubmit}>
                            Update Profile
                        </Button>
                    </div>
                </div>
            </div>        
        </div>
    );
};

export default User;
