import GoogleAuth from './googleauth';
import FacebookAuth from './facebookauth';
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const Login = () => {
    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-0 psyd-font-accent rounded-top text-center">
                        <h1>Sign in with Google</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-0 ">
                    <div className="col-md-12 psyd-primary-bg-color p-0 psyd-font-accent rounded mb-0 mt-0">
                        <GoogleAuth />
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font align-items-center rounded">
                    <div className="col-md-12 p-1 d-flex justify-content-center">
                        Choosing to sign in using your Google account, you can quickly and securely access our platform without the hassle of creating and remembering another password. This streamlined process not only saves you time but also enhances your security, leveraging Google's robust authentication measures. Plus, you’ll have the convenience of managing all your accounts in one place, making it easier to stay connected with your therapist and track your progress on your mental wellness journey. </div>
                </div>

            </div>
        </div>
    )
}

export default Login;