import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import '../css/home.css';
import { Link } from "react-router-dom";
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';



const TherapistProfile = ({ guserSubscriptionPlan }) => {
    const [sessionName, setSessionName] = useState('');
    const [personaName, setPersonaName] = useState('');
    const [selectedPhotourl, setSelectedPhotourl] = useState(null);
    const navigate = useNavigate();


    const handleSessionNameChange = (event) => {
        setSessionName(event.target.value);
        Cookie.set('sessionname', event.target.value);
    };

    const handlePersonaNameChange = (event) => {
        setPersonaName(event.target.value);
        Cookie.set('personaname', event.target.value);
    };

    const handlePhotourlSelection = (photourl) => {
        setSelectedPhotourl(photourl);
        Cookie.set('photourl', photourl);

    };

    const photourls = [
        'Ada.jpg',
        'Amy.jpg',
        'Ann.jpg',
        'Anthony.jpg',
        'Asha.jpg',
        'Brian.jpg',
        'Chris.jpg',
        'Christina.jpg',
        'David.jpg',
        'Jay.jpg',
        'Jennifer.jpg',
        'Kevin.jpg',
        'Mark.jpg',
        'Michelle.jpg',
        'Mike.jpg',
        'Mona.jpg',
        'Olivia.jpg',
        'Raj.jpg',
        'Sue.jpg'
    ];
    useEffect(() => {
        if (guserSubscriptionPlan == 'Guest' || guserSubscriptionPlan == 'Free') {
            navigate('/messages');
        }
        const sessioninfoCookie = Cookies.get('sessioninfo');
        let sessioninfo = {};
        if (sessioninfoCookie) {
            sessioninfo = JSON.parse(sessioninfoCookie);
            setSessionName(decodeURIComponent(sessioninfo.session_name));
            setPersonaName(decodeURIComponent(sessioninfo.persona_name));
            setSelectedPhotourl(decodeURIComponent(sessioninfo.photo_url))
        }
    }, []);;
    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-0 psyd-font-accent rounded-top text-center">
                        <h1>Tailor Therapist Profile</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
                    <div className="col-md-12 p-1 d-flex justify-content-center">
                        To enhance your therapy experience and help you stay organized, we kindly ask you to assign a name to this session. Additionally, please provide the name of your therapist and select a profile picture for your therapist.
                    </div>
                </div>
            </div>
            <div className="container mt-3">
                <div className="row mb-3 g-2">
                    <div className="col-md-3 p-2 rounded psyd-secondary-bg-color">
                        <label>Session Name:</label>
                    </div>
                    <div className="col-md-9">
                        <input type="text" className="form-control" value={sessionName} onChange={handleSessionNameChange} style={{ height: '40px' }} />

                    </div>
                </div>
                <div className="row mb-3 g-2">
                    <div className="col-md-3 p-2 rounded psyd-secondary-bg-color">
                        <label>Therapist Name:</label>
                    </div>
                    <div className="col-md-9">
                        <input type="text" className="form-control" value={personaName} onChange={handlePersonaNameChange} style={{ height: '40px' }} />
                    </div>
                </div>
                <div className="row mb-3 g-2">
                    <div className="col-md-3 p-2 rounded psyd-secondary-bg-color">
                        <label>Therapist Avatar:</label>
                    </div>
                    <div className="col-md-6">
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {photourls.map((photourl, index) => (
                                <img
                                    key={index}
                                    src={`./img/${photourl}`}
                                    alt={`Photourl ${index + 1}`}
                                    onClick={() => handlePhotourlSelection(photourl)}
                                    style={{
                                        width: '50px', // Adjust width as needed
                                        height: '50px',
                                        borderRadius: '50%', // Make image circular
                                        margin: '5px', // Add space between images
                                        border: selectedPhotourl === photourl ? '2px solid blue' : 'none',
                                        cursor: 'pointer',
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                <Link to={'/jobcategory'} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button>&lt; Job Category</Button>
                </Link>
                <Link to={guserSubscriptionPlan != "Premium" ? '/messages' : '/messages'}
                    style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button>{guserSubscriptionPlan != "Premium" ? "Start a Session" : "Start a Session"} &gt;</Button>
                </Link>
            </div>
        </div>
    );
}

export default TherapistProfile;
