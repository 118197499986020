import React, { useState, useEffect } from 'react';
import { Button, Form } from "react-bootstrap";
import axios from 'axios';
import '../css/home.css';
import { Link } from "react-router-dom";
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';



const JobCategory = () => {
    const [refvalues, setrefvalues] = useState([]);
    const [jobcategoryID, setjobcategoryID] = useState(1);

    const fetchJobCategory = async () => {
        try {
            const response = await axios.get('/api/alljobcategory');
            const temprefvalues = response.data.refvalues;
            const parserefvalues = temprefvalues?.map(temprefvalue => JSON.parse(temprefvalue)) || [];
            setrefvalues(parserefvalues);
        } catch (error) {
            console.error('Error fetching job categories', error);
        }
    };
    // Function to handle radio button selection
    const handleRadioChange = (event) => {
        setjobcategoryID(event.target.value); 
        console.log("Selected relationship status:", event.target.value);
        Cookie.set('jobcategoryid', event.target.value);
    };
    useEffect(() => {
        const sessioninfoCookie = Cookies.get('sessioninfo');
        let sessioninfo = {};
        if (sessioninfoCookie) {
            sessioninfo = JSON.parse(sessioninfoCookie);
            setjobcategoryID(sessioninfo.job_category_id);
        }      
        fetchJobCategory();
    }, []);

    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                        <h1>Job Category</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
                    <div className="col-md-12 p-3 d-flex justify-content-center">
                    Job category can significantly impact an individual's mental health. High-stress occupations, such as those in healthcare, finance, and technology, can lead to increased anxiety, burnout, and depression. Conversely, jobs with a strong sense of purpose and social connection, like teaching or social work, can provide positive mental health benefits. When tailoring therapy strategies for individuals, AI-powered platforms can consider their job category to offer targeted techniques, such as stress management exercises, coping mechanisms specific to workplace challenges, and strategies to enhance job satisfaction.                    </div>
                </div>
            </div>
            <div className="container mt-3 psyd-primary-bg-color d-flex justify-content-center align-items-top" style={{ minHeight: '50vh' }}>
                <div className="col">
                    <div className="list-group p-0 d-flex justify-content-center align-items-top">
                        {refvalues?.map((refvalue, index) => (
                            <Form.Check
                                key={index}
                                type="radio"
                                id={`refvalue-${index}`}
                                label={
                                    <span style={{ marginLeft: '20px' }}>{refvalue.job_category}</span> // Add spacing here
                                }
                                value={refvalue.job_category_id}
                                name="refvalue" // Grouping the radio buttons
                                className="psyd-secondary-bg-color rounded mb-2"
                                onChange={handleRadioChange}
                                checked={jobcategoryID == refvalue.job_category_id} 
                                style={{ height: '40px', display: 'flex', alignItems: 'center' }} // Adjust height and center alignment
                            />
                        ))}
                    </div>
                </div>
            </div>
            <br />
          <Link to={'/therapistprofile'} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button>Next</Button>
          </Link>

        </div>
    );
}

export default JobCategory;
