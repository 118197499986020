import React, { useState, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Cookies from 'js-cookie';

const ProcessLogin = ({ changeMenuOption, guserSubscriptionPlan, setSubscriptionPlan }) => {
  const location = useLocation();
  const [loggedinuser, setloggedInUser] = useState('');

  useEffect(() => {
    setSubscriptionPlan();
    changeMenuOption('login', 'Profile', '/profile');
    changeMenuOption('process', 'My Sessions', '/sessions');
    let userinfo = {};
    const userCookie = Cookies.get('userinfo');
    if (userCookie) {
      userinfo = JSON.parse(userCookie);
      setloggedInUser(userinfo.preferred_name);
    } else {
      setloggedInUser('Guest');
    }
  }, [changeMenuOption]); // Ensure it runs once on mount


  return (
    <div id='homediv' className="container-fluid px-0">
      <div className="container-fluid px-4 rounded">
        <div className="row mt-2 mb-0 rounded">
          <div className="col-md-12 psyd-primary-bg-color p-0 psyd-font-accent rounded-top text-center">
            <h1>Welcome {loggedinuser}</h1>
          </div>
        </div>
        <div className="row px-1 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
          <div className="col-sm-12 p-1 ">
            Your login was processed successfully. You are subscribed to the <b>{guserSubscriptionPlan}</b> plan.
          </div>
          {guserSubscriptionPlan == 'Free' && (
            <div className="col-sm-12 p-1">
              Your plan includes provides 60 minutes of therapy time per month and retains history for 3 months.
            </div>
          )}
          {guserSubscriptionPlan == 'Basic' && (
            <div className="col-sm-12 p-1">
              Your plan includes provides 120 minutes of therapy time per month and 12 months of history.
              It also includes Customizable User Interface, Personalized Therapy, and Tailored Therapist Profile.
            </div>
          )}
          {guserSubscriptionPlan == 'Premium' && (
            <div className="col-sm-12 p-1">
              Your plan includes 240 minutes of therapy time per month with 24 months of history retention.
              It also includes Customizable User Interface, Personalized Therapy, Tailored Therapist Profile,
              Past Sessions as PDF, Session Summaries, Human Integration, and Tailored Therapist Personas.
            </div>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
          <Link to='/profile' style={{ display: 'flex', justifyContent: 'center' }}>
            <Button>Update Profile</Button>
          </Link>
          {guserSubscriptionPlan == 'Free' && (
            <Link to='/plans'>
              <Button>Purchase a Plan</Button>
            </Link>
          )}
          <Link to='/sessions' style={{ display: 'flex', justifyContent: 'center' }}>
            <Button>Start a Session</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default ProcessLogin;
