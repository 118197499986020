import GoogleAuth from './googleauth';
import FacebookAuth from './facebookauth';
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const SignUp = () => {
    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-0 psyd-font-accent rounded-top text-center">
                        <h1>Benefits of Signing Up</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-0 psyd-secondary-bg-color psyd-font align-items-center rounded">
                    <div className="col-md-9 p-3 d-flex justify-content-center rounded">
                        If you sign-up for a free account, you’ll receive 60 minutes of Therapy time each month, providing you the opportunity to explore therapy without any financial commitment. This allows you to experience the benefits of professional guidance. Additionally, you can upgrade your plan to enjoy enhanced features such as unlimited sessions, personalized therapy plans, and access to specialized resources, all designed to help you thrive emotionally and mentally.
                    </div>
                    <div className="col-md-3 p-3 d-flex flex-column justify-content-left align-items-center psyd-primary-bg-color">
                        <Link to="/agegroup">
                            <Button>Continue anonymous Guest Session</Button>
                        </Link>
                        <GoogleAuth />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp;