import React from "react";
import { useLocation } from 'react-router-dom';

const Blog = ({ url = "https://blog.psyd.co", width = "100%", height = "500px" }) => {
  const location = useLocation();  
  const query = new URLSearchParams(location.search);
  let postID = query.get('p');
  
  if(postID) {
    url = `https://blog.psyd.co/?p=${postID}`; // Fixed the interpolation
  } else {
    url = "https://blog.psyd.co/";
  }

  return (
    <div style={{ overflow: "hidden", width: "100%", height: "500px" }}>
      <iframe
        src={url}
        width={width}
        height={height}
        style={{ border: "none" }}
        allowFullScreen
        title="Embedded Website"
      ></iframe>
    </div>
  );
};

export default Blog;
