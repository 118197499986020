import { SocialIcon } from 'react-social-icons'
import 'react-social-icons/instagram'
import 'react-social-icons/linkedin'
import 'react-social-icons/mailto'



const About = () => {
    return (

        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 rounded">
                <div className="row mt-2 mb-0 rounded align-items-center">
                    <div className="col-md-12 psyd-primary-bg-color p-0 psyd-font-accent rounded-top text-center">
                        <h1>Our Mission</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font align-items-center rounded">
                    <div className="col-md-6 p-0 d-flex justify-content-center">
                        <div className="card">
                            <img
                                className="card-img-top img-fluid"
                                src="../img/ai-mentalhealth.jpg" // Replace with your image path
                                alt="Guest Session"
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-3">
                        <div>
                            At Psyd, we believe that mental health support should be accessible to everyone, anytime, anywhere. Our mission is to revolutionize the way individuals seek help by harnessing the power of Generative AI to create a user-friendly online therapy platform. We are committed to breaking down barriers to mental wellness, offering personalized, empathetic, and immediate support to those in need. Through innovative technology and a focus on inclusivity, we strive to empower individuals on their journey to better mental health, fostering a world where seeking help is as easy as a click away.
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid px-4 rounded">
                <div className="row mt-2 mb-0 rounded align-items-center">
                    <div className="col-md-12 p-0 psyd-font-accent rounded-top text-center">
                        <h1>Our Approach</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font align-items-center rounded">
                    <div className="col-md-6 p-3">
                        <div>
                            Our technical approach at Psyd leverages cutting-edge Generative AI algorithms to deliver personalized therapy experiences tailored to each user’s unique needs. By combining natural language processing with advanced machine learning, our platform analyzes user interactions to provide real-time, context-aware support. This allows us to create dynamic therapeutic content, including guided sessions, coping strategies, and interactive exercises, all accessible through a seamless interface. We prioritize data security and privacy, ensuring that all user information is protected while providing an intuitive experience that promotes engagement and comfort. Through continuous feedback and adaptation, we aim to refine our algorithms, making mental health care not only accessible but also effective and responsive
                        </div>
                    </div>
                    <div className="col-md-6 p-0 d-flex justify-content-center">
                        <div className="card">
                            <img
                                className="card-img-top img-fluid"
                                src="../img/ai1.jpg" // Replace with your image path
                                alt="Guest Session"
                            />
                        </div>
                    </div>

                </div>
            </div>
            <div className="container-fluid px-4 rounded">
                <div className="row mt-2 mb-0 rounded align-items-center">
                    <div className="col-md-12 p-0 psyd-font-accent rounded-top text-center">
                        <h1>Meet our Expert Team</h1>
                    </div>
                </div>
                <div class="row align-items-top">
                    <div class="col-lg-6 col-sm-6">
                        <div class="">
                            <div class="text-center">
                                <img src="./img/DrSarikaGarga2.png" alt="Image" height="140" width="140" />
                            </div>
                            <div class="psyd-secondary-bg-color psyd-font rounded p-3 mt-2">
                                <h3>Sarika Garga, Ph.D.</h3>
                                <h4>Chief Excutive Officer</h4>
                                <div class="mt-0">
                                    <p>Dr. Sarika Garga has completed her Doctorate in Psychology (Ph.D.) with a
                                        Master's degree in Psychology (MA), and a Masters in Philosophy (M.Phil).She is
                                        credentialed as a "Licensed Clinical Psychologist" in VA and as "Licensed
                                        Psychologist" in Washington DC.
                                    </p>
                                    <p>
                                        Dr. Garga has extensive experience in conducting Psycho-educational Testing,
                                        Developmental Evaluations, Cognitive Abilities Testing, and Testing for Learning Disabilities. She is applying
                                        her expertise in understanding of how humans learn and act
                                        to developing Artifical Intelligence and Machine Learning models.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <div class="">
                            <div class="text-center">
                                <img src="./img//ShreyasGarga.png" alt="Image" width="140" />
                            </div>
                            <div class="psyd-secondary-bg-color psyd-font rounded p-3 mt-2">
                                <h3>Shreyas Garga</h3>
                                <h4>Chief Technology Officer</h4>
                                <div class="mt-0">
                                    <p>Shreyas is a driven data scientist with a Bachelor’s in Neuroscience and experience
                                        in business and social analytics. He has created numerous automated and responsive data
                                        analytics applications and solutions to identify, analyze, and interpret trends and patterns.</p>
                                    <p>
                                        Shreyas is working on combining his knowledge of Neuro-Science and Computer Science
                                        with research and analytical skills to develop libraries and models that can work as foundational frameworks for
                                        Artifical Intelligence and Machine Leaning based solutions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid px-4 rounded">
                <div className="row mt-2 mb-0 rounded align-items-center">
                    <div className="col-md-12 p-0 psyd-font-accent rounded-top text-center">
                        <h1>Contact Us</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font align-items-center rounded">
                    <div className="col-md-6 p-3">
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                        <SocialIcon target="_blank" url="https://www.instagram.com/psyd.co/" />
                        <SocialIcon target="_blank" url=" https://www.threads.net/@psyd.co" /> 
                        <SocialIcon target="_blank" url="https://www.facebook.com/profile.php?id=61571521466789" />
                        <SocialIcon target="_blank" url=" https://www.youtube.com/@psydco" /> 
                        <SocialIcon target="_blank" url=" https://x.com/psydco"/>
                        <SocialIcon target="_blank" url="https://www.linkedin.com/company/psyd-io/" />
                        <SocialIcon url="mailto:admin@psyd.co" network="email" />                        
                        </div>
                    </div>
                    <div className="col-md-6 p-0 d-flex justify-content-center">
                        <div className="card">
                            <img
                                className="card-img-top img-fluid"
                                src="../img/contactus.jpg" // Replace with your image path
                                alt="Guest Session"
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default About;