import React, { useState, useEffect } from 'react';
import { Button, Form } from "react-bootstrap";
import axios from 'axios';
import '../css/home.css';
import { Link } from "react-router-dom";
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';



const ProfessionalStatus = () => {
    const [refvalues, setrefvalues] = useState([]);
    const [professionalstatusID, setprofessionalstatusID] = useState(1);

    const fetchProfessionalStatus = async () => {
        try {
            const response = await axios.get('/api/allprofessionalstatus');
            const temprefvalues = response.data.refvalues;
            const parserefvalues = temprefvalues?.map(temprefvalue => JSON.parse(temprefvalue)) || [];
            setrefvalues(parserefvalues);
        } catch (error) {
            console.error('Error fetching professional status', error);
        }
    };
    // Function to handle radio button selection
    const handleRadioChange = (event) => {
        setprofessionalstatusID(event.target.value);
        console.log("Selected professional status:", event.target.value);
        Cookie.set('professionalstatusid', event.target.value);
    };
    useEffect(() => {
        const sessioninfoCookie = Cookies.get('sessioninfo');
        let sessioninfo = {};
        if (sessioninfoCookie) {
            sessioninfo = JSON.parse(sessioninfoCookie);
            setprofessionalstatusID(sessioninfo.professional_status_id);
        }
        fetchProfessionalStatus();
    }, []);

    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                        <h1>Professional Status</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
                    <div className="col-md-12 p-3 d-flex justify-content-center">
                        Professional status can significantly impact both physical and mental health. Higher-income individuals often have better access to healthcare, nutrition, and stress-reduction techniques, leading to improved overall well-being. Additionally, those in higher-paying professions may experience less job insecurity and have more control over their work environment, reducing stress. These factors can influence the types of therapy, strategies, and techniques needed. For example, someone facing financial hardship may require counseling to address stress and anxiety related to money, while a high-powered executive might benefit from mindfulness techniques to manage work-related stress. Understanding the client's professional status can help tailor therapy to their unique needs and challenges.
                    </div>
                </div>
            </div>
            <div className="container mt-3 psyd-primary-bg-color d-flex justify-content-center align-items-top" style={{ minHeight: '50vh' }}>
                <div className="col">
                    <div className="list-group p-0 d-flex justify-content-center align-items-top">
                        {refvalues?.map((refvalue, index) => (
                            <Form.Check
                                key={index}
                                type="radio"
                                id={`refvalue-${index}`}
                                label={
                                    <span style={{ marginLeft: '20px' }}>{refvalue.professional_status}</span> // Add spacing here
                                }
                                value={refvalue.professional_status_id}
                                name="refvalue" // Grouping the radio buttons
                                className="psyd-secondary-bg-color rounded mb-2"
                                onChange={handleRadioChange}
                                checked={professionalstatusID == refvalue.professional_status_id}
                                style={{ height: '40px', display: 'flex', alignItems: 'center' }} // Adjust height and center alignment
                            />
                        ))}
                    </div>
                </div>
            </div>
            <br />
            <Link to={'/jobcategory'} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button>Next</Button>
            </Link>

        </div>
    );
}

export default ProfessionalStatus;
