import GoogleAuth from './googleauth';
import FacebookAuth from './facebookauth';
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const Login = () => {
    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                        <h1>Benefits of Signing Up</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-0 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded-top">
                    <div className="col-md-12 p-3 d-flex justify-content-center">
                        If you sign-up for a free account, you’ll receive 120 minutes of Therapy time each month, providing you the opportunity to explore therapy without any financial commitment. This allows you to experience the benefits of professional guidance while deciding if our platform is the right fit for your ongoing mental health journey. Additionally, you can upgrade your plan to enjoy enhanced features such as unlimited sessions, personalized therapy plans, and access to specialized resources, all designed to help you thrive emotionally and mentally.
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font align-items-center rounded-bottom justify-content-center">
                    <div className="col-md-12 p-3 d-flex justify-content-center">
                        <Link to="/agegroup">
                            <Button>Continue anonymous Guest Session</Button>
                        </Link>
                    </div>
                </div>
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                        <h1>Sign up with Google</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
                    <div className="col-md-12 p-3 d-flex justify-content-center">
                        Choosing to sign up using your Google account, you can quickly and securely access our platform without the hassle of creating and remembering another password. This streamlined process not only saves you time but also enhances your security, leveraging Google's robust authentication measures. Plus, you’ll have the convenience of managing all your accounts in one place, making it easier to stay connected with your therapist and track your progress on your mental wellness journey. Join us today and take the first step towards a brighter, healthier future! </div>
                </div>
                <div className="row px-0 mt-0 mb-0 ">
                    <div className="col-md-12 psyd-primary-bg-color p-0 psyd-font-accent roundedt">
                        <GoogleAuth />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Login;