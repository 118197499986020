import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Modal, Button } from 'react-bootstrap';

// Modal for Cookie Consent
const CookieConsentModal = () => {
    const [show, setShow] = useState(false);

    // Check if the cookie consent is already accepted
    useEffect(() => {
        const cookieConsent = Cookies.get('cookieConsent');
        if (!cookieConsent) {
            setShow(true); // Show the modal if consent hasn't been given yet
        }
    }, []);

    // Function to handle acceptance of cookies
    const handleAccept = () => {
        // Set the cookie to remember user’s consent
        Cookies.set('cookieConsent', 'accepted', { expires: 365 }); // Cookie expires in 1 year
        setShow(false); // Close the modal
        document.body.style.pointerEvents = 'auto';
    };

    const handleClose = () => setShow(false);

    return (
        <>
            <div>
                <Modal show={show} onHide={handleAccept} centered className="cookie-modal">
                    <Modal.Header closeButton className="modal-header-custom">
                        <h5 className="modal-title">Cookie Notice</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="psyd-primary-bg-color psyd-font-light p-2 rounded" style={{ fontSize: 'small' }}>
                            This website uses cookies to implement essential functionality and ensure the best user experience.
                            Cookies are not used for tracking.
                        </p>
                        <div className="d-flex justify-content-center">
                            <Button variant="secondary" onClick={() => setShow(false)}>
                                Decline
                            </Button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button variant="primary" onClick={handleAccept}>
                                Accept
                            </Button>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer-custom">
                        <p className="text-muted small">We respect your privacy.</p>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};

export default CookieConsentModal;
